import React, { useContext } from "react";
import { FilterContext } from "../../../utils/context";

import * as styles from './filter-group.module.scss'
import { useLocale, useSiteQuery } from "../../../hooks";
import Checkbox from "../Checkbox/Checkbox";
const FilterGroup = ({ filterGroups }) => {
    const { allFilterSections } = useSiteQuery()
    const checked = (e) => {
        if (e.target.checked) {
            e.target.labels[0].classList.add(styles.filterCheckboxContainerChecked)
        }
        else {
            e.target?.labels && e.target.labels[0].classList.remove(styles.filterCheckboxContainerChecked)
        }
    }

    const locale = useLocale()

    const {
        formatFilter,
        setFormatFilter,
        teaTypesFilter,
        setTeaTypesFilter,
        teaBenefitFilter,
        setTeaBenefitFilter,
        flavorFilter,
        setFlavorFilter,
        highlightsFilter,
        setHighlightsFilter,
    } = useContext(FilterContext)

    const onUpdateCheckbox = (item, state, setter) => {
        const isClient = typeof window === 'object'
        if (!isClient) return false
        if (item && !state.includes(item)) {
            setter([...state, item])
        }
        else if (item && state.includes(item)) {
            const index = state.indexOf(item)
            const temp = state
            if (index > -1) {
                temp.splice(index, 1)
                setter([...temp])
            }
        }
    }

    return (
        <>
            <div className={styles.filterGroups}>
                {filterGroups?.map((filterCategory) => {
                    return (
                        <div className={styles.d_flex}>
                            <div className={styles.filters}>
                                <p className={`${styles.filterTitle}`}>{filterCategory.title}</p>
                            </div>
                            <div className={`${styles.filters} ${styles.filterSecond}`}>
                                {
                                    filterCategory.filters.map((filter: any, index: number) => {
                                        let activeState = null;
                                        let activeSetter = null;
                                        let isActive = false;
                                        if (!filterCategory.title || !filter.filterTitle) return;
                                        switch (filterCategory.filterType) {
                                            case 'formatFilter':
                                                activeSetter = setFormatFilter;
                                                activeState = formatFilter;
                                                isActive = formatFilter && formatFilter.includes(filter.title)
                                                break;
                                            case 'teaTypesFilter':
                                                activeSetter = setTeaTypesFilter;
                                                activeState = teaTypesFilter;
                                                isActive = teaTypesFilter && teaTypesFilter.includes(filter.title)
                                                break;
                                            case 'teaBenefitFilter':
                                                activeSetter = setTeaBenefitFilter;
                                                activeState = teaBenefitFilter;
                                                isActive = teaBenefitFilter && teaBenefitFilter.includes(filter.title)
                                                break;
                                            case 'flavorFilter':
                                                activeSetter = setFlavorFilter;
                                                activeState = flavorFilter;
                                                isActive = flavorFilter && flavorFilter.includes(filter.title)
                                                break;
                                            case 'highlightsFilter':
                                                activeSetter = setHighlightsFilter;
                                                activeState = highlightsFilter;
                                                isActive = highlightsFilter && highlightsFilter.includes(filter.title)
                                                break;
                                            default:
                                                break;
                                        }

                                        const clickHandler = () => {
                                            onUpdateCheckbox(filter.filterTitle, activeState, activeSetter);
                                        }

                                        return (
                                            <div key={index} >
                                                <Checkbox index={index} filter={filter} clickHandler={clickHandler} />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default FilterGroup