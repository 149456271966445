import React from "react";
import * as styles from '../FilterGroup/filter-group.module.scss'
const Checkbox = ({
    index,
    filter,
    clickHandler
}) => {
    const checked = (e) => {
        if (e.target.checked) {
            e.target.labels[0].classList.add(styles.filterCheckboxContainerChecked)
        }
        else {
            e.target?.labels && e.target.labels[0].classList.remove(styles.filterCheckboxContainerChecked)
        }
    }
    return (
        <label
            className={`${styles.filterCheckboxContainer} abcd`}
            onClick={(e) => {
                checked(e);
            }}
        >
            <input
                type="checkbox"
                id={`filter-checkbox filter-checkbox-${index}`}
                className={styles.filterCheckbox}
                onChange={()=>{
                    clickHandler()
                }}
            />
            <p className={`${styles.filterTitle} ${styles.filterTitleGrey}`}>{filter.filterTitle}</p>
        </label>
    )
}
export default Checkbox;