// extracted by mini-css-extract-plugin
export var actions = "reccomendedProduct-module--actions--80d70";
export var close = "reccomendedProduct-module--close--2e578";
export var col_1 = "reccomendedProduct-module--col_1--af2b0";
export var col_2 = "reccomendedProduct-module--col_2--941f1";
export var content = "reccomendedProduct-module--content--1aa68";
export var fadeSectionOne = "reccomendedProduct-module--fadeSectionOne--5b2f3";
export var modal = "reccomendedProduct-module--modal--218a1";
export var pop_des = "reccomendedProduct-module--pop_des--29e1c";
export var pop_title = "reccomendedProduct-module--pop_title--8773f";
export var productTitle = "reccomendedProduct-module--product-title--e4b7e";
export var reccomendedImg = "reccomendedProduct-module--reccomendedImg--b9e8f";
export var reccomendedProduct = "reccomendedProduct-module--reccomendedProduct--6dc51";