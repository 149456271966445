import React from "react"
import * as styles from "./reccomendedProduct.module.scss"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { useLocalPath, useDictionaryQuery } from "../../../hooks"
import { useLocale } from "../../../hooks"

const ReccomendedProduct = ({ fluid, text, showButton, to = null }) => {
    const { shopNowButton } = useDictionaryQuery()
    let locale = useLocale()
    showButton= locale== 'es-PE' || locale === 'en-AU' || locale == 'nl-BE' || locale == 'fr-BE' ? false : true
    if (to) {
        return (
            <ul className={` products-thumbnail`}>
                <li className={ styles.reccomendedProduct}>
                <Link to={useLocalPath(`/${to}`)}>
                    
                    <Img fluid={fluid} alt={text} className={styles.reccomendedImg}></Img>
                </Link>
                
                    {/* <p>{text}</p> */}
                    <Link className={styles.productTitle} to={useLocalPath(`/${to}`)}>
                        <p>{text}</p>
                    </Link>
                {showButton && shopNowButton && <Link to={useLocalPath(`/${to}`)}>
                     <button>{shopNowButton}</button>
                </Link>}
                </li>
            </ul>
        )
    } else {
        return (
            <div className={styles.reccomendedProduct}>
                <Img fluid={fluid} alt={text} className={styles.reccomendedImg}></Img>
                <p>{text}</p>
                {showButton && <button>{shopNowButton}</button>}
            </div>
        )

    }
}

export default ReccomendedProduct
