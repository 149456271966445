// extracted by mini-css-extract-plugin
export var actions = "reccomendedProductQuickView-module--actions--e142d";
export var btn__secondary = "reccomendedProductQuickView-module--btn__secondary--3f83d";
export var close = "reccomendedProductQuickView-module--close--740f0";
export var col_1 = "reccomendedProductQuickView-module--col_1--75959";
export var col_2 = "reccomendedProductQuickView-module--col_2--2c4a5";
export var content = "reccomendedProductQuickView-module--content--a5a00";
export var modal = "reccomendedProductQuickView-module--modal--d1249";
export var pop_des = "reccomendedProductQuickView-module--pop_des--723c6";
export var pop_title = "reccomendedProductQuickView-module--pop_title--11d52";
export var productsThumbnail = "reccomendedProductQuickView-module--products-thumbnail--d3561";
export var reccomendedImg = "reccomendedProductQuickView-module--reccomendedImg--72100";
export var reccomendedProduct = "reccomendedProductQuickView-module--reccomendedProduct--b5da4";
export var textBar = "reccomendedProductQuickView-module--textBar--157ed";